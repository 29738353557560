import React from "react";
import { Switch, Router, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";

import LoginSSO from "./pages/Login/sso";
import { PrivateRoute } from "./components/ProtectedRoute/protectedRoute";
import Transfers from "./pages/Transfers";
import DeviceManagement from "./pages/DeviceManagement";
import PeopleManagement from "./pages/PeopleManagement";
import CustomerAreaManagement from "./pages/CustomerAreaManagement";
import Login from "./pages/Login";
import Monitoring from "./pages/Monitoring";
import Preconfigured from "./pages/PreConfigured";
import MonitoringMap from "./pages/MonitoringMap";
import { Scripting } from "./pages/Scripting";
import { ReportPage } from "./pages/Report/index";
import ReportEventDetail from "./pages/ReportEventDetail";
import DeviceTrackMapReport from "./pages/Report/IdentifierReport/components/DeviceTrackMapReport";
import POIManagement from "./pages/POIManagement";
import { ScriptingPDF } from "./pages/Scripting/ScriptingPDF";

export const history = createBrowserHistory();

const Routes = () => (
  <Router history={history}>
    <Switch>
      <PrivateRoute
        can="can-view-preconfigured-transfers"
        exact
        path="/preconfigured"
        component={Preconfigured} /*isPrivate*/
      />
      <Route exact path="/login/sso" component={LoginSSO} />
      <PrivateRoute
        can="can-monitoring-devices"
        exact
        path="/monitoringmap"
        component={MonitoringMap} /*isPrivate*/
      />
      <PrivateRoute
        can="can-monitoring-devices"
        exact
        path="/monitoring"
        component={Monitoring} /*isPrivate*/
      />
      <PrivateRoute
        can="can-view-transfer-history"
        exact
        path="/reports"
        component={ReportPage} /*isPrivate*/
      />
      <PrivateRoute
        exact
        path="/reports/:deviceId/:from/:to/track"
        component={DeviceTrackMapReport} /*isPrivate*/
      />
      <PrivateRoute
        exact
        path="/reports/:deviceId/:transferId/:startDate/:end_date"
        component={ReportEventDetail} /*isPrivate*/
      />
      <PrivateRoute
        can="can-view-routes"
        exact
        path="/transfers"
        component={Transfers} /*isPrivate*/
      />
      <PrivateRoute
        can="can-manage-devices"
        exact
        path="/devicemanagement"
        component={DeviceManagement} /*isPrivate*/
      />
      <PrivateRoute
        can="can-manage-devices"
        exact
        path="/poimanagement"
        component={POIManagement} /*isPrivate*/
      />
      <PrivateRoute
        can="can-scripting-transfer"
        exact
        path="/scripting"
        component={Scripting} /*isPrivate*/
      />
      <PrivateRoute
        can="can-scripting-transfer"
        exact
        path="/scripting/pdf"
        component={ScriptingPDF} /*isPrivate*/
      />
      <Route path="/" exact component={Login} />
      <PrivateRoute
        can="can-edit-fields"
        exact
        path="/fieldmanagement"
        component={CustomerAreaManagement}
      />
      <PrivateRoute
        can="can-manage-people"
        exact
        path="/managementpeople"
        component={PeopleManagement} /*isPrivate*/
      />
      <PrivateRoute
        path="*"
        component={() => <Redirect to={"/monitoringmap"} />}
      />
    </Switch>
  </Router>
);

export default Routes;
