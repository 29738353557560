import React from 'react'
import { ToastContainer } from 'material-react-toastify'
import { PersistGate } from 'redux-persist/integration/react'
import {Provider} from 'react-redux'

import { store, persistor } from './store'
import Routes from './routes'
import 'material-react-toastify/dist/ReactToastify.css'
import './global.css'


require('dotenv').config()

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ToastContainer position="top-right" />
        <Routes />
       </PersistGate>
    </Provider>
  )
}

export default App;
