import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  success: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: 10,
    height: 50,
  },

  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    width: 10,
    height: 50,
  },

  message: {
    display: 'flex',
    alignItems: 'center'
  },

  iconVariant: {
    marginRight: 10
  },

  closeIcon: {
    color: theme.palette.primary.contrastText,
    marginLeft: 190,
    marginTop: -80
  }
}));
