import axios from 'axios'

import * as middlewares from './middlewares'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_UTRANSFER_URL,
})

middlewares.addUserAuthorization(api)

export default api
