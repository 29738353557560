import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import useStyles from './styles';

const CustomSnackbar = () => {

  const classes = useStyles();

  const dispatch = useDispatch();
  const snackbarState = useSelector(state => state.snackbar);

  const variantIcon = {
    success: CheckCircleIcon,
    error: ErrorIcon
  };

  const renderIcon = () => {
    const Icon = variantIcon[snackbarState.type];

    if (Icon)
      return <Icon className={classes.iconVariant} />;
    else
      return null;
  };

  return (
    <Snackbar
      open={snackbarState.open}
      autoHideDuration={3000}
      onClose={() => dispatch({ type: 'CLOSE_SNACKBAR' })}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <SnackbarContent
        classes={{ root: classes[snackbarState.type] }}
        message={
          <span className={classes.message}>
            {renderIcon()}
            {snackbarState.message}
          </span>
        }
        action={
          <IconButton
            onClick={() => dispatch({ type: 'CLOSE_SNACKBAR' })}
          >
            <CloseIcon classes={{ root: classes.closeIcon }} />
          </IconButton>
        }
      />
    </Snackbar>
  );

};

export default CustomSnackbar;
