import transferApi from "../../services/transferApi";
import devicesAPI from "../../services/devices";
import * as exceptions from "../../commons/exceptions";

export const getTransfers = async ({
  page,
  per_page,
  scheduled_date,
  event,
  from,
  to,
  unity,
  responsible,
}) => {
  try {
    const { data: transfers } = await transferApi.get("/transfers", {
      params: {
        page,
        per_page,
        scheduled_date,
        event,
        from,
        to,
        unity,
        responsible,
      },
    });

    return transfers;
  } catch (error) {
    switch (error?.response?.status) {
      case 401:
        throw exceptions.createException({ code: "unauthorized" });
      case 403:
        throw exceptions.createException({ code: "forbidden" });
      default:
        throw exceptions.createException({
          code: "forbidden",
          error,
        });
    }
  }
};

export const getTransfersWithDevices = async ({
  page = 0,
  rowsPerPage = 1,
  event = "",
  identifier = "",
  responsible = "",
  scheduledDateBeforeEqualDate = "",
  scheduledDateAfterEqualDate = "",
  unity = "",
}) => {
  try {
    const { data: transfersWithDevices } = await transferApi.get("/transfers", {
      params: {
        page: `${page + 1}`,
        per_page: `${rowsPerPage}`,
        event,
        identifier,
        responsible,
        scheduled_date_lte: scheduledDateBeforeEqualDate,
        scheduled_date_gte: scheduledDateAfterEqualDate,
        unity,
      },
    });

    return transfersWithDevices;
  } catch (error) {
    switch (error?.response?.status) {
      case 401:
        throw exceptions.createException({
          code: "unauthorized",
          error,
        });
      case 403:
        throw exceptions.createException({
          code: "forbidden",
          error,
        });
      default:
        throw exceptions.createException({
          code: "api-request-failed",
          error,
        });
    }
  }
};

export const removeTransfers = async (transferIds, customerId) => {
  try {
    const { data } = await devicesAPI.delete(
      `/customer/${customerId}/transfers`,
      {
        data: transferIds,
      }
    );
    const transfersDeleted = data.transfers_deleted;

    return transfersDeleted;
  } catch (error) {
    let code = "";
    switch (error?.response?.status) {
      case 400:
        code = "wrong-body-format";
        break;
      case 401:
        code = "unauthorized";
        break;
      case 403:
        code = "forbidden";
        break;
      case 422:
        code = "no-transfer-ids-provided";
        break;
      default:
        code = "internal-server-error";
        break;
    }

    throw exceptions.createException({
      code,
      error,
    });
  }
};
