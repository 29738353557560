import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 0 500px;
  justify-content: space-evenly;
`;

export const Div = styled.div`
  display: flex;
  margin-left: 26px;
`;