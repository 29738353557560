export const ScriptingActiontypes = {
  SET_DIRECTIONS: "SET_DIRECTIONS",

  SET_DISPLAYED_OCCURRENCES: "SET_DISPLAYED_OCCURRENCES",
  SET_RETRIEVED_FILTERED_OCCURRENCES: "SET_RETRIEVED_FILTERED_OCCURRENCES",
  SET_OCCURRENCES_CATEGORIES: "SET_OCCURRENCES_CATEGORIES",
  SET_CURRENT_TANSFER_EDITABLE_INFO: "SET_CURRENT_TANSFER_EDITABLE_INFO",
  SET_RISK_LAYERS: "SET_RISK_LAYERS",

  SET_WAYPOINTS: "SET_WAYPOINTS",
  ADD_WAYPOINT: "ADD_WAYPOINT",
  UPDATE_WAYPOINT: "UPDATE_WAYPOINT",
  REMOVE_WAYPOINT: "REMOVE_WAYPOINT",

  SET_INCIDENTS_NATURES: "SET_INCIDENTS_NATURES",

  SET_MAP_CLICK_TO_STOP: "SET_MAP_CLICK_TO_STOP",
  SET_MAP_CLICK_TO_DETOUR: "SET_MAP_CLICK_TO_DETOUR",
  SET_MAP_CLICK_TO_NONE: "SET_MAP_CLICK_TO_NONE",

  SET_SECURITY_ALERTS: "SET_SECURITY_ALERTS",

  SET_RADIUS: "SET_RADIUS",
  SET_AFTER_DATE: "SET_AFTER_DATE",

  CAN_BIND_DEVICE: "CAN_BIND_DEVICE",
  SET_CREATED_TRANSFER: "SET_CREATED_TRANSFER",

  START_LOADING_DIRECTIONS: "START_LOADING_DIRECTIONS",
  FINISH_LOADING_DIRECTIONS: "FINISH_LOADING_DIRECTIONS",

  SET_SIDEBAR_CONTENT_OPEN: "SET_SIDEBAR_CONTENT_OPEN",

  OPEN_MAP_STYLE_DRAWER: "OPEN_MAP_STYLE_DRAWER",
  CHANGE_MAP_STYLES_SCRIPTING: "CHANGE_MAP_STYLES_SCRIPTING",

  SET_ACTIVE_RISK_LAYERS: "SET_ACTIVE_RISK_LAYERS",

  CLEAR_SCRIPTING_DATA: "CLEAR_SCRIPTING_DATA",
};

const INITIAL_VALUES = {
  directions: null,
  mapClickAddStopOrDetour: "",
  sidebarContentOpen: "default",
  securityAlerts: [],
  incidentsNatures: [],
  waypoints: [],
  canRenderDirectionsService: true,
  canRenderDirections: false,
  displayedOccurrences: null,
  occurrencesCategories: null,
  retrievedFilteredOccurrences: null,
  currentTransferEditableInfo: {},
  riskLayers: [],
  canBindDevice: false,
  createdTransfer: null,
  mapType: "roadmap",
  activeRiskLayers: [185],
};

export const scriptingReducer = (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case ScriptingActiontypes.CLEAR_SCRIPTING_DATA:
      return INITIAL_VALUES;

    case ScriptingActiontypes.ADD_WAYPOINT:
      return {
        ...state,
        waypoints: [...state.waypoints, action.payload],
      };

    case ScriptingActiontypes.UPDATE_WAYPOINT:
      return {
        ...state,
        waypoints: state.waypoints.map((waypoint) =>
          waypoint.id === action.payload.id ? action.payload : waypoint
        ),
      };

    case ScriptingActiontypes.SET_WAYPOINTS:
      return {
        ...state,
        waypoints: [...action.payload],
      };

    case ScriptingActiontypes.REMOVE_WAYPOINT:
      return {
        ...state,
        waypoints: state.waypoints.filter(
          (waypoint) => waypoint.id !== action.payload
        ),
      };

    case ScriptingActiontypes.SET_SIDEBAR_CONTENT_OPEN:
      return {
        ...state,
        sidebarContentOpen: action.payload,
      };

    case ScriptingActiontypes.START_LOADING_DIRECTIONS:
      return {
        ...state,
        canRenderDirections: false,
        canRenderDirectionsService: true,
      };

    case ScriptingActiontypes.FINISH_LOADING_DIRECTIONS:
      return {
        ...state,
        canRenderDirections: true,
        canRenderDirectionsService: false,
      };

    case ScriptingActiontypes.SET_SECURITY_ALERTS:
      return { ...state, securityAlerts: action.payload };

    case ScriptingActiontypes.SET_INCIDENTS_NATURES:
      return { ...state, incidentsNatures: action.payload };

    case ScriptingActiontypes.SET_DIRECTIONS:
      return { ...state, directions: action.payload };

    case ScriptingActiontypes.SET_MAP_CLICK_TO_STOP:
      return { ...state, mapClickAddStopOrDetour: "stop" };

    case ScriptingActiontypes.SET_MAP_CLICK_TO_DETOUR:
      return { ...state, mapClickAddStopOrDetour: "detour" };

    case ScriptingActiontypes.SET_MAP_CLICK_TO_NONE:
      return { ...state, mapClickAddStopOrDetour: "" };

    case ScriptingActiontypes.SET_DISPLAYED_OCCURRENCES:
      return {
        ...state,
        displayedOccurrences: { ...action.payload },
      };

    case ScriptingActiontypes.SET_CURRENT_TANSFER_EDITABLE_INFO:
      return {
        ...state,
        currentTransferEditableInfo: { ...action.payload },
      };

    case ScriptingActiontypes.SET_RETRIEVED_FILTERED_OCCURRENCES:
      return {
        ...state,
        retrievedFilteredOccurrences: action.payload,
      };

    case ScriptingActiontypes.SET_OCCURRENCES_CATEGORIES:
      return {
        ...state,
        occurrencesCategories: action.payload,
      };

    case ScriptingActiontypes.SET_RISK_LAYERS:
      return {
        ...state,
        riskLayers: action.payload,
      };

    case ScriptingActiontypes.CAN_BIND_DEVICE:
      return {
        ...state,
        canBindDevice: action.payload,
      };

    case ScriptingActiontypes.SET_CREATED_TRANSFER:
      return {
        ...state,
        createdTransfer: action.payload,
      };

    case ScriptingActiontypes.OPEN_MAP_STYLE_DRAWER:
      return { ...state, openMapStyleDrawer: !state.openMapStyleDrawer };

    case ScriptingActiontypes.CHANGE_MAP_STYLES_SCRIPTING:
      return { ...state, mapType: action.mapType };

    case ScriptingActiontypes.SET_ACTIVE_RISK_LAYERS:
      return { ...state, activeRiskLayers: action.payload };

    default:
      return state;
  }
};

// Actions

export const clearScriptingData = () => ({
  type: ScriptingActiontypes.CLEAR_SCRIPTING_DATA,
});

export const changeMapStylesAction = (mapType) => ({
  type: ScriptingActiontypes.CHANGE_MAP_STYLES_SCRIPTING,
  mapType,
});

export const openMapStyleDrawerAction = () => ({
  type: ScriptingActiontypes.OPEN_MAP_STYLE_DRAWER,
});

export const setRiskLayersAction = (riskLayers) => ({
  type: ScriptingActiontypes.SET_RISK_LAYERS,
  payload: riskLayers,
});

export const setOccurrencesCategoriesAction = (occurrencesCategories) => ({
  type: ScriptingActiontypes.SET_OCCURRENCES_CATEGORIES,
  payload: occurrencesCategories,
});

export const setRetrievedFilteredOccurrencesAction = (
  retrievedFilteredOccurrences
) => ({
  type: ScriptingActiontypes.SET_RETRIEVED_FILTERED_OCCURRENCES,
  payload: retrievedFilteredOccurrences,
});

export const setCurrentTransferEditableInfo = (
  currentTransferEditableInfo
) => ({
  type: ScriptingActiontypes.SET_CURRENT_TANSFER_EDITABLE_INFO,
  payload: currentTransferEditableInfo,
});

export const setDisplayedOccurrences = (displayedOcurrencces) => ({
  type: ScriptingActiontypes.SET_DISPLAYED_OCCURRENCES,
  payload: displayedOcurrencces,
});

export const addWaypointAction = (waypoint) => ({
  type: ScriptingActiontypes.ADD_WAYPOINT,
  payload: waypoint,
});

export const setWaypointsAction = (waypoints) => ({
  type: ScriptingActiontypes.SET_WAYPOINTS,
  payload: waypoints,
});

export const updateWaypointAction = (waypoint) => ({
  type: ScriptingActiontypes.UPDATE_WAYPOINT,
  payload: waypoint,
});

export const removeWaypointByIdAction = (waypointId) => ({
  type: ScriptingActiontypes.REMOVE_WAYPOINT,
  payload: waypointId,
});

export const setIncidentsNaturesAction = (incidents) => ({
  type: ScriptingActiontypes.SET_INCIDENTS_NATURES,
  payload: incidents,
});

export const setDirectionsAction = (directions) => ({
  type: ScriptingActiontypes.SET_DIRECTIONS,
  payload: directions,
});

export const setMapClickAddStopAction = () => ({
  type: ScriptingActiontypes.SET_MAP_CLICK_TO_STOP,
});

export const setMapClickAddDetourAction = () => ({
  type: ScriptingActiontypes.SET_MAP_CLICK_TO_DETOUR,
});

export const setMapClickAddNoneAction = () => ({
  type: ScriptingActiontypes.SET_MAP_CLICK_TO_NONE,
});

export const setSecurityAlertsAction = (securityAlertsArray) => ({
  type: ScriptingActiontypes.SET_SECURITY_ALERTS,
  payload: securityAlertsArray,
});

export const startLoadingDirectionsAction = () => ({
  type: ScriptingActiontypes.START_LOADING_DIRECTIONS,
});

export const finishLoadingDirectionsAction = () => ({
  type: ScriptingActiontypes.FINISH_LOADING_DIRECTIONS,
});

export const setSidebarContentOpen = (sidebarNameOpen) => ({
  type: ScriptingActiontypes.SET_SIDEBAR_CONTENT_OPEN,
  payload: sidebarNameOpen,
});

export const setCanBindDevice = (boolean) => ({
  type: ScriptingActiontypes.CAN_BIND_DEVICE,
  payload: boolean,
});

export const setCreatedTransfer = (createdTransfer) => ({
  type: ScriptingActiontypes.SET_CREATED_TRANSFER,
  payload: createdTransfer,
});

export const setActiveRiskLayers = (riskZone) => ({
  type: ScriptingActiontypes.SET_ACTIVE_RISK_LAYERS,
  payload: riskZone,
});
